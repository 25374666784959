<template>
<!--  todo: переработан на vue только этот селектор. Он встроен в форму _fos_form в v_fos.php - там очень старое легаси -->
  <div class="_datetime_selector_widget field_wrapper only_call only_call_now">
    <label>Перезвонить:</label>
    <!-- todo: пришлость пока индекс заменить на конкретное значение, чтобы в форму шло сразу значение, 
    т.к. форма отправляется дефолтно -->
    <select v-model="selectedDate" 
            name="date" 
            class="_datetime_selector _date_selector inline_select date_select"
    >
      <option v-for="(option, index) in filteredDate" :value="option.data" v-bind:key="index">
        {{ option.content }}
      </option>
    </select>
    <!-- todo: пришлость пока индекс заменить на конкретное значение, чтобы в форму шло сразу значение, 
    т.к. форма отправляется дефолтно -->
    <select
      v-model="selectedTime"
      name="time"
      class="_time_selector inline_select time_select"
    >
      <option v-for="(option, index) in filteredTime" :value="option.data" v-bind:key="index">
        {{ option.content }}
      </option>
    </select>
    <input type="hidden" name="timezone_offset" :value="timezoneOffset">
  </div>
</template>

<script>
export default {
  name: "ConsultationRecallDateTimeSelector",
  data() {
    return {
      dateSelectors: [],
      timeSelectors: [],
      selectedDate: '',
      selectedTime: '',
      timezoneOffset: 0
    }
  },
  computed: {
    selectedDateIndex() {
      const selectedOption = this.filteredDate.filter(item => item.data === this.selectedDate)[0];
      
      const index = this.filteredDate.indexOf(selectedOption);
      return index === -1 ? 0 : index;
    },

    selectedTimeIndex() {
      const selectedOption = this.filteredTime.filter(item => item.data === this.selectedTime)[0];
      
      const index = this.filteredTime.indexOf(selectedOption);
      return index === -1 ? 0 : index;
    },
    
    filteredDate() {
      return this.dateSelectors.filter(i => !i.holiday);
    },

    filteredTime() {
      if (this.dateSelectors.length === 0) {
        return [];
      }

      return this.dateSelectors[this.selectedDateIndex].today ?
          this.timeSelectors.filter(i => !i.past) : this.timeSelectors;
    },

    timeRecall() {
      return `${this.filteredDate[this.selectedDateIndex].content}`+
          ` ${'в ' + this.timeInMoscowTimezone}`;
    },

    timeInMoscowTimezone() {
      const localTimeArr = this.filteredTime[this.selectedTimeIndex].data.split(':');

      const localHour = localTimeArr[0];
      const hourInMoscow = localHour - this.timezoneOffset;

      return hourInMoscow + ':' + localTimeArr[1];
    },
  },
  methods: {
    setTimezoneOffset() {
      const localTime = new Date();

      //  Смещение относительно Московского часового пояса будет положительным для местного часового пояса,
      //  находящегося западнее часового пояса UTC и отрицательным — восточнее (поэтому минус перед скобкой).
      //  И вычитаем три, чтобы получить смещение относительно Москвы.
      this.timezoneOffset = - (localTime.getTimezoneOffset() / 60) - 3;
      this.$emit('timezoneOffset', this.timezoneOffset);
    },
    getDateAndTimeSelector() {
      const params = {
        "timezone_offset": this.timezoneOffset,
      };

      return fetch(`/api/date_and_time_selectors`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params)
      })
        .then(response => response.json())
        .then(dateAndTimeSelectors => {
          this.dateSelectors = dateAndTimeSelectors.dateSelector;
          this.timeSelectors = dateAndTimeSelectors.timeSelector;
          
          this.selectedDate = this.filteredDate[0].data;
          this.selectedTime = this.filteredTime[0].data;
        });
    },
    getRecallTime() {
      return this.timeRecall;
    }
  },
  async beforeMount() {
    this.setTimezoneOffset();
    await this.getDateAndTimeSelector();
  },

  watch: {
    selectedDate() {
      this.$emit('timeRecall', this.timeRecall);
    },

    selectedTime() {
      this.$emit('timeRecall', this.timeRecall);
    },
  }
}
</script>

<style scoped>

</style>