import Vue from 'vue';
import VModal from 'vue-js-modal';
import ClientFeedback from './ClientFeedback.vue';

Vue.use(VModal, { dialog: true });

window.vue_client_feedback = new Vue({
  el: '#vue_client_feedback',
  render: h => h(ClientFeedback),
});

window.jQuery('._clientFeedback').live('click', function () {
  window.vue_client_feedback.$children[0].showClientFeedbackModal();
});
