<template>
  <modal
    name="clientFeedback"
    :width="600"
    height="auto"
    :scrollable="true"
    :adaptive="true"
    classes="modalStyle"
    @closed="closeModal"
  >
    <img
      src="./_img/closeIcon.png"
      alt="close"
      width="30"
      height="28"
      class="closeImg"
      @click="closeModal"
    />

    <div class="content">
      <div class="head">
        <p style="text-align: center;">Здравствуйте!</p>
        <br>
        <p>Укажите контактные данные и удобное время для звонка — мы обязательно свяжемся с вами и ответим на все вопросы.</p>
      </div>

      <div
        @submit.prevent="submit"
        class="form"
      >
        <div class="inputs">
          <div class="row">
            <span class="label">Телефон:</span>
            <InputValidation
              :value="userPhone"
              :is-valid="isValidPhone"
              :ignore-touched-props="ignoreTouched"
              inside-text="+7 902 330-56-65"
              mask="+{7} (000) 000-00-00"
              @input="inputPhone"
            />
          </div>
          <ConsultationRecallDateTimeSelector
            @timeRecall="updateTimeRecall"
            @timzoneOffset="setTimezoneOffset"
          />
        </div>

        <div class="personalDataAgreement">
          <input type="checkbox" v-model="privacyPolicyChecked"
                 :class="[privacyPolicyChecked ? '' : 'invalid']">
          <p>Даю согласие на обработку моего e-mail и телефона в соответствии с
            <a class="blueLink" href="https://export-base.ru/privacy_policy" rel="noopener noreferrer"
               target="_blank">политикой конфиденциальности ExportBase</a>
          </p>
        </div>

        <img src="./_img/arrow.png" class="imgArrow" alt="" width="18" height="20"/>

        <span
          v-if="!sent && !error"
          class="button"
          @click="submit">
          Заказать звонок
        </span>

        <div
          v-if="sent"
          class="success"
        >
          <p style="text-align: center;">Спасибо, заявка успешно отправлена!</p>
          <p>Менеджер свяжется с вами в указанное время.</p>
        </div>

        <div
          v-if="error"
          class="error"
        >
          <p style="text-align: center;">Произошла ошибка, не удалось отправить заявку!</p>
          <p>
            Пожалуйста, свяжитесь с менеджером по телефону <a href="tel:+78007752912" target="_blank">8 800 775-29-12</a>
            <br>
            или Email <a href="mailto:manager@export-base.ru" target="_blank">manager@export-base.ru</a>
          </p>
        </div>

      </div>
    </div>
  </modal>
</template>

<script>
import InputValidation from 'input-validation';
import ConsultationRecallDateTimeSelector
  from "./ConsultationRecallDateTimeSelector.vue";
import axios from "axios";

export default {
  name: "ClientFeedback",
  components: {ConsultationRecallDateTimeSelector, InputValidation},

  data() {
    return {
      ignoreTouched: false,

      userPhone: '',
      timeRecall: '',
      timezoneOffset: 0,
      privacyPolicyChecked: true,

      sent: false,
      error: false,
    }
  },

  computed: {
    isValidPhone() {
      const regExpPhone = /^[+]?[\d() -]{16}\d$/;
      return regExpPhone.test(this.userPhone);
    },
  },

  methods: {
    showClientFeedbackModal() {
      window.document.querySelector('[data-tab_button="main"]').style.zIndex = 0;
      this.$modal.show('clientFeedback');
    },

    closeModal() {
      window.document.querySelector('[data-tab_button="main"]').style.zIndex = '';
      this.$modal.hide('clientFeedback');
    },

    inputPhone(phone) {
      this.userPhone = phone;
    },

    updateTimeRecall(timeRecall) {
      this.timeRecall = timeRecall;
    },

    setTimezoneOffset(timezoneOffset) {
      this.timezoneOffset = timezoneOffset;
    },

    async submit() {
      if (!this.isValidPhone || !this.privacyPolicyChecked) {
        this.ignoreTouched = true;
        return;
      }

      if (this.sent) {
        return;
      }

      try {
        await axios.post('/api/client_feedback', {
          phone: this.userPhone,
          recall_time: this.timeRecall,
          timezone_offset: this.timezoneOffset,
        });

        window.echo('Заявка отправлена');
        this.sent = true;
        this.error = false;
      } catch (e) {
        this.error = true;
        this.sent = false;
      }
    },
  },
}
</script>

<style scoped lang="less">
.closeImg {
  position: absolute;
  z-index: 1;
  right: -10px;
  top: -10px;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.head {
  line-height: 1.2;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.inputs {
  margin-bottom: 20px;
  font-size: 13px;
}

.label {
  color: #9C9C9C;
}

.personalDataAgreement {
  display: flex;
  margin: 2% 0 4% 15%;
  font-size: 13px;
  input {
    align-self: flex-start;
    &.invalid {
      box-shadow: 0 0 10px red;
    }
  }
  p {
    margin: 0 0 0 1.5%;
  }
}

.imgArrow {
  margin-bottom: 20px;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 1px #333;
  text-align: center;
  background: linear-gradient(0deg, #facf02 40%, #fce800);
  font-size: 25px;
  font-style: italic;
  font-weight: 700;
  color: #353b31;
}
.button:hover {
  box-shadow: 0 0 4px #333;
}

.success, .error {
  color: crimson;
  font-weight: bold;
  p {
    margin-bottom: 10px;
    line-height: 1.5;
  }
}
.success {
  color: #7ba331;
}
.error {
  color: crimson;
}

/deep/.modalStyle {
  border: 3px solid #FFD800;
  border-radius: 10px;
  overflow: visible;
  top: 30% !important;
  padding: 40px 40px;
  font-size: 15px;
}

/deep/.vm--overlay {
  height: 100% !important;
}
</style>
